import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export const Preload = ({ href, as, crossOrigin, media }) => {
  return (
    <Helmet>
      <link
        rel="preload"
        as={as}
        href={href}
        crossOrigin={crossOrigin}
        media={media}
      />
    </Helmet>
  );
};

Preload.defaultProps = {
  crossOrigin: undefined,
  media: undefined
};

Preload.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired,
  crossOrigin: PropTypes.oneOf(['anonymous', 'use-credentials']),
  media: PropTypes.string
};
